<template>
    <modal name="mdl-carrinho-compras" classes="container-carrinho-compras" width="900" height="auto" @before-close="alteraQtdProdutos()">
        <div v-if="isLoading" class="loader-pulse pulse">
            <img :src="'https://'+clienteSac.endServidor+'/controller/ecommerce/'+configPathsLogos.logoIcone" alt="...">
        </div>
        <template v-else>
            <div class="left-carrinho">
                <div class="titulo-carrinho">
                    <h2 v-if="qtdItensCarrinho <= 2">Produtos adicionados ({{ qtdItensCarrinho }} item)</h2>
                    <h2 v-else>Produtos adicionados ({{ qtdItensCarrinho }} itens)</h2>
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                </div>
                <div class="divisoria-carrinho"></div> <!-- DIVISÓRIA -->  
                <div class="lista-produtos-carrinho y-scroll">
                    <template v-for="produto in lstProdutosCarrinho">
                        <!--
                        Verifica se o produto ta indisponível
                            Motivos de indisponibilidade: (Listar aqui caso sejam adicionados mais motivos.)
                            - Tabela de valores selecionada não tem preço para esse produto, logo veio zerado.
                            - Produto sem estoque.
                            - Marcado como não exibir no ecommerce.
                        -->
                        <template v-if="parseFloat(produto.valorproduto) <= 0">
                            <div v-if="produto.qtdproduto != 0" class="produto-carrinho">
                                <div class="foto-produto">
                                    <div class="qtd-item-carrinho"><span>x</span></div>
                                    <img :src="'https://'+clienteSac.endServidor+'/controller/ecommerce/'+produto.imagem.patharquivo" alt="">
                                </div>
                                <div class="descricao-produto">
                                    <p>{{ produto.nomeproduto.toUpperCase() }}</p>
                                    <div class="divisoria-carrinho" style="width: 95%;"></div>  <!-- DIVISÓRIA -->
                                    <div class="footer-produto-carrinho">
                                        <div class="seletor-quantidade-carrinho">
                                            <button disabled class="btn-menos"><i class="fa fa-minus" aria-hidden="true"></i></button>
                                            <input disabled v-model="produto.qtdproduto" type="text" name="" id="">
                                            <button disabled class="btn-mais"><i class="fa fa-plus" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="total-por-produto-carrinho">
                                    <span style="color: rgba(173, 0, 0, 0.689);">Produto Indisponível</span>
                                </div>
                                <div class="remover-produto">
                                    <button @click="produto.qtdproduto = 0"><i class="fa fa-times" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div v-if="produto.qtdproduto != 0" class="produto-carrinho">
                                <div class="foto-produto">
                                    <div class="qtd-item-carrinho"><span>{{ produto.qtdproduto }}</span></div>
                                    <img :src="'https://'+clienteSac.endServidor+'/controller/ecommerce/'+produto.imagem.patharquivo" alt="">
                                </div>
                                <div class="descricao-produto">
                                    <p>{{ produto.nomeproduto.toUpperCase() }}</p>
                                    <div class="divisoria-carrinho" style="width: 95%;"></div>  <!-- DIVISÓRIA -->
                                    <!-- <money class="total-produto-carrinho" v-bind="money" v-model="produto.valorReal"></money> -->
                                    <div class="footer-produto-carrinho">
                                        <div class="seletor-quantidade-carrinho">
                                            <button class="btn-menos" @click="produto.qtdproduto--"><i class="fa fa-minus" aria-hidden="true"></i></button>
                                            <input v-model="produto.qtdproduto" type="text" name="" id="">
                                            <button class="btn-mais" @click="produto.qtdproduto++"><i class="fa fa-plus" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="total-por-produto-carrinho">
                                    <span>{{formataReal(produto.qtdproduto*parseFloat(produto.valorproduto), 'R$')}}</span>
                                </div>
                                <div class="remover-produto">
                                    <button @click="produto.qtdproduto = 0"><i class="fa fa-times" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
            <div class="right-carrinho">
                <div class="titulo-carrinho">
                    <h2>Resumo do seu carrinho</h2>
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                </div>
                <div class="divisoria-carrinho"></div>  <!-- DIVISÓRIA -->
                <div class="campo-cep-carrinho">
                    <div class="tipo-entrega-carrinho">
                        <div class="wrap-check-64-c">
                            <label class="switch" for="checkbox">
                                <input type="checkbox" id="checkbox" v-model="vCheckRetirar" disabled/>
                                <div class="slider round"></div>
                            </label>
                        </div>
                        <label v-if="vCheckRetirar" for="checkbox" class="activeCheck-carrinho">Retirar na Loja</label>
                        <label v-else for="checkbox">Retirar na Loja</label>
                    </div>
                    <div v-if="!vCheckRetirar" class="card" style="padding: 5px;">
                        <div class="input-cep-carrinho">
                            <label for="iCep">CEP</label>
                            <div class="grupo-cep-carrinho">
                                <input id="iCep" type="text" placeholder="Digite seu cep" v-mask="'#####-###'" v-model="usuarioLogado.cepecommerce">
                                <button @click="confirmaCep">OK</button>
                            </div>
                        </div>
                        <div class="info-cep-carrinho">
    
                        </div>
                    </div>
                    <div v-else class="card container-infos-retirada">
                        <div class="infos-retirada-header">
                            <i class="fa fa-shopping-bag" aria-hidden="true"></i><span>Endereço para retirada</span>
                        </div>
                        <div class="infos-retirada-body">
                            <span><strong>{{ filial.fantasia }}</strong></span>
                            <p>{{ filial.endereco.logradouro }}, {{ filial.endereco.numero }}</p>
                            <p>{{ filial.endereco.descbairro }} - CEP: {{ formataCEP(filial.endereco.cep) }}</p>
                            <p>{{ filial.endereco.desccidade }} - {{ filial.endereco.iduf }}</p>
                        </div>
                    </div>
                </div>
                <div class="divisoria-carrinho" style="margin-top: auto;"></div>  <!-- DIVISÓRIA -->
                <div class="rodape-carrinho">
                    <div class="codigo-promocao-carrinho"></div>
                    <div class="calculos-carrinho">
                        <div class="valor-subtotal">
                            <p>Subtotal</p>
                            <span>{{ formataReal(subtotalCarrinho, 'R$') }}</span>
                        </div>
                        <div class="valor-frete">
                            <p>Frete</p>
                            <span v-if="!vCheckRetirar">R$0,00</span>
                            <span v-else style="color: green;">Frete Grátis</span>
                        </div>
                        <div class="valor-descontos">
                            <p>Descontos</p>
                            <span>{{ formataReal(descontoCarrinho, 'R$')}}</span>
                        </div>
                        <div class="valor-total">
                            <p>Total</p>
                            <span>{{ formataReal(totalcarrinho, 'R$') }}</span>
                        </div>
                    </div>
                    <div class="divisoria-carrinho"></div>
                    <div class="finalizar-carrinho">
                        <div class="valor-total"></div>
                        <div class="botoes-finalizar">
                            <button @click.prevent="finalizarPedido()">Finalizar compra</button>
                            <button @click.prevent="$modal.hide('mdl-carrinho-compras')">Continuar comprando</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </modal>
</template>
<script>
import utils from '@/utilitarios/utils';
import {Money} from 'v-money'
export default {
    name: 'CartDesktop',
    props: {
        // msg: String
    },
    components: {
        Money
    },
    data: function() {
        return {
            isLoading               : true,
            // CONFIGURAÇÃO COMPONENTE MONEY
            money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'R$',     //aqui colocar o cifrão se for necessário
                    precision: 2,
                    masked: false
                },

            // CONFIGURAÇÕES DO CLIENTE
            temaFilial              : '', // AQUI TRÁS O TEMA DE CORES ESCOLHIDO NA CONFIGURAÇÃO DO WEBSITE NO SISTEMA
            
            // DADOS CARRINHO
            qtdItensCarrinho        : '',
            vCep                    : '',
            vCheckRetirar           : true,
            lstProdutosCarrinho     : [],
        };
    },
    methods: {
        carregaProdutosCarrinho(){
            let vself = this;
            let parametros = [
                {parametro: 'op',               valor: 'listarcarrinho'},
                {parametro: 'idcliente',        valor: this.usuarioLogado.id},
				{parametro: 'idtabelaprecos',	valor: this.filial.idtabelaprecos},
                {parametro: 'idclientesac',     valor: this.clienteSac.id},
				{parametro: 'pathimagens',		valor: this.configPaths.pathImgProdutos},
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtcarrinhocompras.php');
            retorno.then(function (response) {
                vself.lstProdutosCarrinho = response.data
                vself.qtdItensCarrinho = vself.lstProdutosCarrinho.length
                vself.$store.commit('MUDAR_QTD_CARRINHO_COMPRAS', vself.qtdItensCarrinho);
                vself.lstProdutosCarrinho.forEach(produto => {
                    produto.imagem = vself.ordenaImagensProduto(produto.imagem)[0]

                    // if (parseFloat(produto.valorproduto) > 0){
                    //     vself.subtotalCarrinho += parseFloat(produto.valorproduto) * produto.qtdproduto;
                    //     vself.descontoCarrinho += parseFloat(produto.desconto) * produto.qtdproduto;
                    // }

                });
                vself.isLoading = false;
            }).catch(function (response) {
                vself.zeraDadosCarrinho();
                console.log(response.message);
                vself.isLoading = false;
            });
        },
        alteraQtdProdutos(){
            let vself = this;
            let parametros = [
                {parametro: 'op',               valor: 'alterarqtdprodutos'},
                {parametro: 'idcliente',        valor: this.usuarioLogado.id},
                {parametro: 'idclientesac',     valor: this.clienteSac.id},
				{parametro: 'pathimagens',		valor: this.configPaths.pathImgProdutos},
                {parametro: 'produtos',         valor:  JSON.stringify(vself.lstProdutosCarrinho)}
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtcarrinhocompras.php');
            retorno.then(function (response) {
                vself.carregaProdutosCarrinho();
                vself.$store.commit('MUDAR_QTD_CARRINHO_COMPRAS', vself.qtdItensCarrinho);
            }).catch(function (response) {
                vself.zeraDadosCarrinho();
                console.log(response.message);
            });
        },
        zeraDadosCarrinho(){
            this.lstProdutosCarrinho = [];
            this.qtdItensCarrinho = '';
        },
        formataReal(valor, prefix) {
            return utils.formataReal(valor, prefix)
        },
        formataCEP(cep){
			if (cep){
				return cep.substring(0, 5) + '-' + cep.substring(5, 8);
			}
		},
        confirmaCep(){

        },
        ordenaImagensProduto(lstimagens){
			let lstordenada = lstimagens.sort((a, b) => {
				if (a.arquivo < b.arquivo) {
					return 1;
				}
				if (a.arquivo > b.arquivo) {
					return -1;
				}
				return 0;
			});
			return lstordenada;
		},
        finalizarPedido(){
            let vself = this;
            vself.isLoading = true;
            vself.alteraQtdProdutos();
            let parametros = [
                {parametro: 'op',               valor: 'validapedido'},
                {parametro: 'idcliente',        valor: this.usuarioLogado.id},
                {parametro: 'idclientesac',     valor: this.clienteSac.id},
                {parametro: 'idfilial',         valor: this.filial.id},
            ];
            let retorno = utils.reqAxios(parametros, 'post', vself.clienteSac.endServidor+'/controller/ecommerce/rtcarrinhocompras.php');
            retorno.then(function (response) {
                setTimeout(() => {
                    if (response.data.inf <= 0){
                        vself.$toastr.w(response.data.msg, 'Ops!');
                        vself.carregaProdutosCarrinho();
                    } else {
                        vself.$router.push({name: 'Checkout'})
                    }
                }, 500);
            }).catch(function (response) {
                vself.zeraDadosCarrinho();
                console.log(response.message);
            });
        }
    },
    computed: {
        clienteSac(){
			return this.$store.state.clienteSac;
		},
		filial(){
			return this.$store.state.filial;
		},
		destaquesProdutos(){
			return this.$store.state.destaquesProdutos;
		},
		usuarioLogado() {
			return this.$store.state.usuarioLogado;
		},
		configPaths(){
			return this.$store.state.configPaths;
		},
		configPathsLogos(){
			return this.$store.state.configPathsLogos;
		},
		produtosAleatorios(){
			return this.$store.state.produtosAleatorios;
		},
        subtotalCarrinho(){
            let subtotalCarrinho = 0;
            this.lstProdutosCarrinho.forEach(produto => {
                if (parseFloat(produto.valorproduto) > 0){
                    subtotalCarrinho += parseFloat(produto.valorproduto) * produto.qtdproduto;
                }
            });
            return subtotalCarrinho;
        },
        descontoCarrinho(){
            let descontoCarrinho = 0;
            this.lstProdutosCarrinho.forEach(produto => {
                if (parseFloat(produto.desconto) > 0){
                    descontoCarrinho += parseFloat(produto.desconto) * produto.qtdproduto;
                }
            });
            return descontoCarrinho;
        },
        totalcarrinho(){
            return this.subtotalCarrinho - this.descontoCarrinho;
        }
    },
    watch: {
        // VERIFICA SE A QTD DE ALGUM DOS ITENS CHEGOU A ZERO E ATUALIZA A LISTA
        lstProdutosCarrinho: {
            handler: function (novaListaProdutos, antigaListaProdutos) {
                novaListaProdutos.forEach(produtonovo => {
                    if (produtonovo.qtdproduto <= 0) {
                        this.alteraQtdProdutos();
                    }
                });
            },
            deep: true
        },

    },
    mounted: function() {
        // this.$modal.show('mdl-carrinho-compras');
        this.carregaProdutosCarrinho();
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.loader-pulse{
  width: 100%;
  height: 65vh;
  // background-color: red;
}
.divisoria-carrinho {
    width: 100%;
    height: 2px;
    background-color: rgb(220, 220, 220);
    margin-block: 4px;
}

.vm--modal.container-carrinho-compras {
    display: flex;
    justify-content: space-between;
    border-radius: 15px;
    background-color: rgb(230, 230, 230);
    padding: 15px;
}

.left-carrinho {
    background-color: #fff;
    border-top-left-radius: 15px;
    padding: 15px;
    width: 59%;
    height: 80vh;
}
.right-carrinho {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    width: 39%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.campo-cep-carrinho{
    width: 100%;
    // background-color: red;
    height: 100px;
}
.tipo-entrega-carrinho{
    display: flex;
    margin-block: 5px;
    & > label {
        margin: 0;
        display: flex;
        height: 28px;
        margin-left: 7px;
        color: #717171;
        font-weight: 500;
    }
}
.activeCheck-carrinho {
    color: var(--cor-primaria) !important;
}

.titulo-carrinho {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h2 {
        height: 100%;
        font-size: 18px;
        font-weight: 600;
        padding: 0;
        margin: 0;
    }
    & > i {
        text-align: center;
        height: 100%;
        font-size: 20px;
    }
}
.lista-produtos-carrinho {
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    height: 93%;
    overflow-y: auto;
    background-color: rgb(230, 230, 230);
}

.rodape-carrinho {
    // height: fit-content;
    width: 100%;
    // background-color: blue;
}

.input-cep-carrinho {
    width: fit-content;
    display: flex;
    text-align: start;
    flex-direction: column;
    padding-inline: 5px;
    & > label {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
    }
}

.grupo-cep-carrinho {
    & > input[type=text] {
        padding-left: 15px;
        height: 35px;
        width: 150px;
        font-size: 15px;
        outline: 0;
        color: #717171;
        border: 1px solid rgb(185, 185, 185);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    & > button {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        background-color: var(--cor-primaria);
        color: #fff;
        width: 50px;
        height: 35px;
        font-weight: 600;
        border-radius: 5px;
        border: 0;
    }
}

.info-cep-carrinho{
    // background-color: red;
    width: 100%;
    height: 100px;
}

.calculos-carrinho{
    display: flex;
    flex-direction: column;
    & > .valor-subtotal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > p {
            font-size: 20px;
            color: rgb(133, 133, 133);
            font-weight: 600;
            margin: 0;
        }
        & > span {
            font-size: 18px;
            color: rgb(133, 133, 133);
            margin: 0;
        }
    }
    & > .valor-frete {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > p {
            margin: 0;
            font-size: 18px;
            color: rgb(133, 133, 133);
        }
        & > span {
            margin: 0;
            font-size: 18px;
            color: rgb(133, 133, 133);
        }
    }
    & > .valor-descontos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > p {
            margin: 0;
            font-size: 18px;
            color: rgb(133, 133, 133);
        }
        & > span {
            margin: 0;
            font-size: 18px;
            color: rgb(133, 133, 133);
            text-decoration: line-through;
        }
    }
    & > .valor-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > p {
            font-size: 25px;
            font-weight: 600;
            margin: 0;
        }
        & > span {
            font-size: 20px;
            margin: 0;
        }
    }
}

.finalizar-carrinho{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    & > .botoes-finalizar {
        display: flex;
        flex-direction: column;
        padding-inline: 10px;
        :first-child {
            border: 0;
            width: 220px;
            height: 40px;
            border-radius: 10px;
            background-color: var(--cor-primaria);
            color: #fff;
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 8px;
            &:hover {
                background-color: var(--cor-primaria-hover);
            }
        }
        :last-child {
            border: 2px solid #b1b1b1;
            font-size: 16px;
            font-weight: 600;
            background-color: #fff;
            width: 220px;
            height: 40px;
            border-radius: 10px;
            color: #3e3e3e;
        }

    }
}

.produto-carrinho {
    display: flex;
    width: 100%;
    height: 90px;
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 5px;

    & > .foto-produto {
        height: 100%;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        & > .qtd-item-carrinho {
            position: absolute;
            background-color: rgb(208, 208, 208);
            color: #242424;
            padding-inline: 3px;
            min-width: 23px;
            height: 23px;
            border-radius: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 8px;
            right: -10px;
            & > span {
                font-weight: 600;
            }
        }
        & > img {
            margin-left: 10px;
            padding: 4px;
            border-radius: 10px;
            border: 2px solid rgb(208, 208, 208);
            width: 100%;
        }
    }
    & > .descricao-produto {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        margin-left: 23px;
        text-align: left;
        & > p {
            font-size: 12px;
            font-weight: 600;
            width: 95%;
            display: -webkit-box;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            margin-left: 5px;
        }
        & > span {
            margin: 0;
            font-weight: 600;
            color:#242424b9 ;
        }

    }
    & >.remover-produto {
        height: 100%;
        width: fit-content;
        padding-top: 5px;
        padding-right: 5px;
        & > button {
            background-color: #fff;
            color: rgba(173, 0, 0, 0.689);
            border: 0;
            &:hover {
                color: rgba(216, 0, 0, 0.97);
            }
        }
    }
}
.footer-produto-carrinho {
    width: 100%;
    // background-color: red;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 10px;
}

.seletor-quantidade-carrinho {
    & > .btn-mais {
        width: 30px;
        background-color: #f3f3f3;
        border: 2px solid rgb(233, 233, 233);
        color: #2424246f;
    }
    & > input[type="text"]{
        width: 30px;
        text-align: center;
        // font-weight: 600;
        background-color: #fff;
        border-left: 0;
        border-top: 2px solid rgb(233, 233, 233);
        border-bottom: 2px solid rgb(233, 233, 233);
        border-right: 0;
    }
    & > .btn-menos {
        width: 30px;
        background-color: #f3f3f3;
        border: 2px solid rgb(233, 233, 233);
        color: #2424246f;
        // font-size: 20px;
    }
}

.total-por-produto-carrinho {
    // background-color: red;
    height: 100%;
    width: 150px;
    display: flex;
    align-items: center;
    // justify-content: center;
    & > span {
        font-size: 14px;
        font-weight: 600;
    }
}

// ESTILIZAÇÃO CHECKBOX
.wrap-check-64-c {
    margin-top: 2px;
    display: flex;
    align-items: center;
}
.wrap-check-64-c .switch {
    display: inline-block;
    height: 20px;
    width: 35px;
    position: relative;
}

.wrap-check-64-c .switch input {
    display:none;
}

.wrap-check-64-c .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}

.wrap-check-64-c .slider:before {
    background-color: #fff;
    bottom: 3px;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    position: absolute;
    transition: .4s;
}

.wrap-check-64-c input:checked + .slider {
    background-color: var(--cor-primaria);
}

.wrap-check-64-c input:checked + .slider:before {
    transform: translateX(13px);
}

.wrap-check-64-c .slider.round {
    border-radius: 34px;
}

.wrap-check-64-c .slider.round:before {
    border-radius: 50%;
}

.container-infos-retirada{
    display: flex;
    flex-direction: column;
}

.infos-retirada-header{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    padding-block: 5px;
    color: #4b4b4b;
    background-color: #dbdbdb;

    & > span {
        margin: 0;
        margin-left: 5px;
        font-size: 16px;
        font-weight: 500;
    }
}

.infos-retirada-body {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-block: 10px;
    padding-inline: 20px;
    font-size: 14px;
    & > span {
        font-weight: 700;
    }
    & > p {
        font-weight: 500;
        margin: 0;
        color: #727272;
    }
}
</style>
